import React from 'react'
import './reportPage.css'

export default function ReportPage() {
  return (
    <>
      <h2 className='noContent'>Отчетность</h2>
      <div className='report_content'>
        <ol>
          <li>
            <p>Протокол очередного общего собрания собственников помещений в многоквартирном доме по адресу: Нижегородская область,
              город Нижний Новгород, ул. Березовская, дом 96/1 в форме очно-заочного голосования от 03.03.2025 года. <a target='_blank' href='/downloads/protocol1.pdf'>Просмотр</a></p>
          </li>
          <li>
            <p>Отчет об исполнении управляющей организацией договора управления, а также отчет о выполнении за 2024 год, по адресу г. Нижний Новгород, ул. Деловая, д.24к2 <a target='_blank' href='/downloads/Delovaya_24_1_2024.pdf'>Просмотр</a></p>
          </li>
          <li>
            <p>Отчет об исполнении управляющей организацией договора управления, а также отчет о выполнении за 2024 год, по адресу г. Нижний Новгород, ул. Моховая, д.11 <a target='_blank' href='/downloads/Mohovaya_11_2024.pdf'>Просмотр</a></p>
          </li>
          <li>
            <p>Отчет об исполнении управляющей организацией договора управления, а также отчет о выполнении за 2024 год, по адресу г. Нижний Новгород, ул. Берёзовская, д.96к1 <a target='_blank' href='/downloads/Berezovskaya_96_1_2024.pdf'>Просмотр</a></p>
          </li>
        </ol>
      </div>
    </>

  )
}
